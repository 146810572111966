// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_tabsRoot__5X8SZ {
  margin-bottom: 108px;
}
.styles_tabsRoot__5X8SZ .styles_tabsList__1CgE5 {
  display: flex;
}
.styles_tabsRoot__5X8SZ .styles_tabsList__1CgE5 .styles_buttonTab__Wgjg9 {
  background-color: transparent;
  border-color: #717171;
  border-style: solid;
  border-width: 0 0 2px 0;
  color: #717171;
  flex: 1 1;
  font-size: 18px;
  font-weight: 700;
  text-transform: capitalize;
}
.styles_tabsRoot__5X8SZ .styles_tabsList__1CgE5 .styles_buttonTabFoccus__Hqa6Y {
  color: #2D3073;
  border-color: #2D3073;
}`, "",{"version":3,"sources":["webpack://./src/components/filterAddActivation/styles.module.sass","webpack://./src/styles/_colors.sass"],"names":[],"mappings":"AAEA;EACE,oBAAA;AADF;AAGE;EACE,aAAA;AADJ;AAGI;EACE,6BAAA;EACA,qBCKI;EDJJ,mBAAA;EACA,uBAAA;EACA,cCEI;EDDJ,SAAA;EACA,eAAA;EACA,gBAAA;EACA,0BAAA;AADN;AAGI;EACE,cCZK;EDaL,qBCbK;ADYX","sourcesContent":["@import 'styles/_colors.sass'\n\n.tabsRoot\n  margin-bottom: 108px\n\n  .tabsList\n    display: flex\n\n    .buttonTab\n      background-color: transparent\n      border-color: $dark-50\n      border-style: solid\n      border-width: 0 0 2px 0\n      color: $dark-50\n      flex: 1\n      font-size: 18px\n      font-weight: 700\n      text-transform: capitalize\n\n    .buttonTabFoccus\n      color: $blue-600\n      border-color: $blue-600\n","$background-menu: #F8F8FB\n\n$ligth-blue-600: #00559A\n\n$edit-icon: #6661AB\n\n$blue-200: #7973BF\n$blue-500: #404085\n$blue-600: #2D3073\n$blue-700: #182160\n$blue-800: #002A67\n$blue-900: #00174F\n\n$border-bottom-title: #BBBBBB\n\n$dark-50: #717171\n$dark-100: #5A5A5A\n$dark-200: #444444\n$dark-300: #2D2D2D\n\n$green-400: #00D04C\n$green-600: #00AC29\n$green-700: #009A14\n\n$light-300: #B4B4B4\n$light-400: #9E9E9E\n$light-700: #686868\n\n$red-500: #FF3333\n$red-600: #d32f2f\n$red-700: #A72626\n\n$statusLabelActive: #00BE3B\n$statusLabelPause: #535098\n$statusLabelClose: #EF4D43\n$statusLabelUnused: #9E9E9E\n\n$tag-text: #003F80\n\n$white: #fff\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tabsRoot": `styles_tabsRoot__5X8SZ`,
	"tabsList": `styles_tabsList__1CgE5`,
	"buttonTab": `styles_buttonTab__Wgjg9`,
	"buttonTabFoccus": `styles_buttonTabFoccus__Hqa6Y`
};
export default ___CSS_LOADER_EXPORT___;
