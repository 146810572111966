// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_form__pxJ9V {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
}
.styles_form__pxJ9V .styles_select__F1BX1 {
  margin-bottom: 48px;
}`, "",{"version":3,"sources":["webpack://./src/components/formDistributionCenter/styles.module.sass"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,gBAAA;AACF;AACE;EACE,mBAAA;AACJ","sourcesContent":[".form\n  display: flex\n  flex-direction: column\n  margin-top: 24px\n\n  .select\n    margin-bottom: 48px\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": `styles_form__pxJ9V`,
	"select": `styles_select__F1BX1`
};
export default ___CSS_LOADER_EXPORT___;
