// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_title__WtDLO {
  color: #002A67;
  font-size: 18px;
  font-weight: 700;
  margin: 24px;
}

.styles_container__w4QUn {
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;
}
.styles_container__w4QUn .styles_distributionForm__xZyUq {
  flex: 1 1;
  margin-right: 88px;
}
.styles_container__w4QUn .styles_tabsForm__qw9y1 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1.5 1;
  margin-right: 70px;
}
.styles_container__w4QUn .styles_errorFilters__PQSkZ {
  color: #A72626;
  margin-bottom: 24px;
  font-size: 0.75rem;
}`, "",{"version":3,"sources":["webpack://./src/screens/add-distribution-center/styles.module.sass","webpack://./src/styles/_colors.sass"],"names":[],"mappings":"AAGA;EACE,cCMS;EDLT,eAAA;EACA,gBAAA;EACA,YAAA;AAFF;;AAIA;EACE,aAAA;EACA,8BAAA;EACA,mBAAA;AADF;AAGE;EACE,SAAA;EACA,kBAAA;AADJ;AAGE;EACE,aAAA;EACA,sBAAA;EACA,8BAAA;EACA,WAAA;EACA,kBAAA;AADJ;AAGE;EACE,cCIM;EDHN,mBAAA;EACA,kBAAA;AADJ","sourcesContent":["@import 'styles/_colors.sass'\n\n// Global page styles\n.title\n  color: $blue-800\n  font-size: 18px\n  font-weight: 700\n  margin: 24px\n\n.container\n  display: flex\n  justify-content: space-between\n  margin-bottom: 50px\n\n  .distributionForm\n    flex: 1\n    margin-right: 88px\n\n  .tabsForm\n    display: flex\n    flex-direction: column\n    justify-content: space-between\n    flex: 1.5\n    margin-right: 70px\n  \n  .errorFilters\n    color: $red-700\n    margin-bottom: 24px\n    font-size: 0.75rem\n","$background-menu: #F8F8FB\n\n$ligth-blue-600: #00559A\n\n$edit-icon: #6661AB\n\n$blue-200: #7973BF\n$blue-500: #404085\n$blue-600: #2D3073\n$blue-700: #182160\n$blue-800: #002A67\n$blue-900: #00174F\n\n$border-bottom-title: #BBBBBB\n\n$dark-50: #717171\n$dark-100: #5A5A5A\n$dark-200: #444444\n$dark-300: #2D2D2D\n\n$green-400: #00D04C\n$green-600: #00AC29\n$green-700: #009A14\n\n$light-300: #B4B4B4\n$light-400: #9E9E9E\n$light-700: #686868\n\n$red-500: #FF3333\n$red-600: #d32f2f\n$red-700: #A72626\n\n$statusLabelActive: #00BE3B\n$statusLabelPause: #535098\n$statusLabelClose: #EF4D43\n$statusLabelUnused: #9E9E9E\n\n$tag-text: #003F80\n\n$white: #fff\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `styles_title__WtDLO`,
	"container": `styles_container__w4QUn`,
	"distributionForm": `styles_distributionForm__xZyUq`,
	"tabsForm": `styles_tabsForm__qw9y1`,
	"errorFilters": `styles_errorFilters__PQSkZ`
};
export default ___CSS_LOADER_EXPORT___;
