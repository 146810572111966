// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_container__PreK7 {
  display: flex;
  flex: 1 1;
  justify-content: space-around;
}
.styles_container__PreK7 .styles_icon__X6Qgp {
  color: #FF3333;
  width: 34px;
  height: 34px;
}
.styles_container__PreK7 .styles_iconEdit__UlxGw {
  color: #6661AB;
}
.styles_container__PreK7 .styles_icon__X6Qgp:hover {
  cursor: pointer;
}
.styles_container__PreK7 .styles_iconDisabled__7LJC0 {
  color: #9E9E9E;
  height: 34px;
  width: 34px;
}
.styles_container__PreK7 .styles_eyeIcon__ZkzI\\+ {
  border: #6661AB 1px solid;
  border-radius: 50px;
  color: #6661AB;
  padding: 3px;
  width: 27px;
  height: 27px;
}`, "",{"version":3,"sources":["webpack://./src/components/tableActions/styles.module.sass","webpack://./src/styles/_colors.sass"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,SAAA;EACA,6BAAA;AADF;AAGE;EACE,cCoBM;EDnBN,WAAA;EACA,YAAA;AADJ;AAGE;EACE,cCTQ;ADQZ;AAGE;EACE,eAAA;AADJ;AAGE;EACE,cCMQ;EDLR,YAAA;EACA,WAAA;AADJ;AAGE;EACE,yBAAA;EACA,mBAAA;EACA,cCtBQ;EDuBR,YAAA;EACA,WAAA;EACA,YAAA;AADJ","sourcesContent":["@import 'styles/_colors.sass'\n  \n.container\n  display: flex\n  flex: 1\n  justify-content: space-around\n\n  .icon\n    color: $red-500\n    width: 34px\n    height: 34px\n\n  .iconEdit\n    color: $edit-icon\n\n  .icon:hover\n    cursor: pointer\n\n  .iconDisabled\n    color: $light-400\n    height: 34px\n    width: 34px\n\n  .eyeIcon\n    border: $edit-icon 1px solid\n    border-radius: 50px\n    color: $edit-icon\n    padding: 3px\n    width: 27px\n    height: 27px","$background-menu: #F8F8FB\n\n$ligth-blue-600: #00559A\n\n$edit-icon: #6661AB\n\n$blue-200: #7973BF\n$blue-500: #404085\n$blue-600: #2D3073\n$blue-700: #182160\n$blue-800: #002A67\n$blue-900: #00174F\n\n$border-bottom-title: #BBBBBB\n\n$dark-50: #717171\n$dark-100: #5A5A5A\n$dark-200: #444444\n$dark-300: #2D2D2D\n\n$green-400: #00D04C\n$green-600: #00AC29\n$green-700: #009A14\n\n$light-300: #B4B4B4\n$light-400: #9E9E9E\n$light-700: #686868\n\n$red-500: #FF3333\n$red-600: #d32f2f\n$red-700: #A72626\n\n$statusLabelActive: #00BE3B\n$statusLabelPause: #535098\n$statusLabelClose: #EF4D43\n$statusLabelUnused: #9E9E9E\n\n$tag-text: #003F80\n\n$white: #fff\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `styles_container__PreK7`,
	"icon": `styles_icon__X6Qgp`,
	"iconEdit": `styles_iconEdit__UlxGw`,
	"iconDisabled": `styles_iconDisabled__7LJC0`,
	"eyeIcon": `styles_eyeIcon__ZkzI+`
};
export default ___CSS_LOADER_EXPORT___;
