// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:root {
  --border-table-color: #444444;
  --text-table-color: #686868;
  --arrows-table-color: #002a67;
  --arrows-table-color-disabled: #00000042;

  --toast-success: #12cd8a;
  --toast-error: #ff3333;
}

body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  background-color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Open Sans', sans-serif;
}

.swiper,
.swiper-wrapper {
  z-index: 0;
  padding: 2px 0;
}

.swiper-slide {
  height: unset;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.Toastify__toast-container {
  top: 13%;
}

.Toastify__toast {
  display: flex;
  align-items: center;
}

.Toastify__toast--success {
  border: 1px var(--toast-success) solid;
  color: var(--toast-success) !important;
  box-shadow: 2px 2px 2px 0px rgba(0, 0, 0, 0.1);
}

.Toastify__toast--error {
  border: 1px var(--toast-error) solid;
  color: var(--toast-error) !important;
  box-shadow: 2px 2px 2px 0px rgba(0, 0, 0, 0.1);
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,6BAA6B;EAC7B,2BAA2B;EAC3B,6BAA6B;EAC7B,wCAAwC;;EAExC,wBAAwB;EACxB,sBAAsB;AACxB;;AAEA;EACE,SAAS;EACT,oCAAoC;EACpC,uBAAuB;EACvB,mCAAmC;EACnC,kCAAkC;AACpC;;AAEA;EACE,oCAAoC;AACtC;;AAEA;;EAEE,UAAU;EACV,cAAc;AAChB;;AAEA;EACE,aAAa;AACf;;AAEA,UAAU;AACV;EACE,UAAU;EACV,WAAW;AACb;;AAEA,UAAU;AACV;EACE,mBAAmB;AACrB;;AAEA,WAAW;AACX;EACE,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA,oBAAoB;AACpB;EACE,gBAAgB;AAClB;;AAEA;EACE,QAAQ;AACV;;AAEA;EACE,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,sCAAsC;EACtC,sCAAsC;EACtC,8CAA8C;AAChD;;AAEA;EACE,oCAAoC;EACpC,oCAAoC;EACpC,8CAA8C;AAChD","sourcesContent":[":root {\n  --border-table-color: #444444;\n  --text-table-color: #686868;\n  --arrows-table-color: #002a67;\n  --arrows-table-color-disabled: #00000042;\n\n  --toast-success: #12cd8a;\n  --toast-error: #ff3333;\n}\n\nbody {\n  margin: 0;\n  font-family: 'Open Sans', sans-serif;\n  background-color: white;\n  -webkit-font-smoothing: antialiased;\n  -moz-osx-font-smoothing: grayscale;\n}\n\ncode {\n  font-family: 'Open Sans', sans-serif;\n}\n\n.swiper,\n.swiper-wrapper {\n  z-index: 0;\n  padding: 2px 0;\n}\n\n.swiper-slide {\n  height: unset;\n}\n\n/* width */\n::-webkit-scrollbar {\n  width: 5px;\n  height: 5px;\n}\n\n/* Track */\n::-webkit-scrollbar-track {\n  background: #f1f1f1;\n}\n\n/* Handle */\n::-webkit-scrollbar-thumb {\n  background: #888;\n  border-radius: 4px;\n}\n\n/* Handle on hover */\n::-webkit-scrollbar-thumb:hover {\n  background: #555;\n}\n\n.Toastify__toast-container {\n  top: 13%;\n}\n\n.Toastify__toast {\n  display: flex;\n  align-items: center;\n}\n\n.Toastify__toast--success {\n  border: 1px var(--toast-success) solid;\n  color: var(--toast-success) !important;\n  box-shadow: 2px 2px 2px 0px rgba(0, 0, 0, 0.1);\n}\n\n.Toastify__toast--error {\n  border: 1px var(--toast-error) solid;\n  color: var(--toast-error) !important;\n  box-shadow: 2px 2px 2px 0px rgba(0, 0, 0, 0.1);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
