// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_form__U5zxt {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
}
.styles_form__U5zxt .styles_selects__82\\+M\\+ {
  margin-bottom: 48px;
}
.styles_form__U5zxt .styles_selects__82\\+M\\+ > span {
  color: #FF3333;
  font-size: 0.9rem;
  font-weight: bold;
  margin-top: 0.3rem;
}
.styles_form__U5zxt .styles_input__EKCcJ {
  margin-bottom: 48px;
}
.styles_form__U5zxt .styles_dateInputs__hXyuc {
  display: flex;
}
.styles_form__U5zxt .styles_dateInputs__hXyuc .styles_dateInputsMargin__ooVeL {
  margin-right: 8px;
}

.styles_errorDate__PgO1m {
  margin: 0;
  margin-top: 5px;
  color: #d32f2f;
  font-size: 0.75rem;
}`, "",{"version":3,"sources":["webpack://./src/components/formActivation/styles.module.sass","webpack://./src/styles/_colors.sass"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,sBAAA;EACA,gBAAA;AADF;AAGE;EACE,mBAAA;AADJ;AAEI;EACE,cCkBI;EDjBJ,iBAAA;EACA,iBAAA;EACA,kBAAA;AAAN;AAEE;EACE,mBAAA;AAAJ;AAEE;EACE,aAAA;AAAJ;AAEI;EACE,iBAAA;AAAN;;AAEA;EACE,SAAA;EACA,eAAA;EACA,cCEQ;EDDR,kBAAA;AACF","sourcesContent":["@import 'styles/_colors.sass'\n\n.form\n  display: flex\n  flex-direction: column\n  margin-top: 24px\n\n  .selects\n    margin-bottom: 48px\n    > span\n      color: $red-500\n      font-size: 0.9rem\n      font-weight: bold\n      margin-top: 0.3rem\n\n  .input\n    margin-bottom: 48px\n\n  .dateInputs\n    display: flex\n\n    .dateInputsMargin\n      margin-right: 8px\n\n.errorDate\n  margin: 0\n  margin-top: 5px\n  color: $red-600\n  font-size: 0.75rem\n","$background-menu: #F8F8FB\n\n$ligth-blue-600: #00559A\n\n$edit-icon: #6661AB\n\n$blue-200: #7973BF\n$blue-500: #404085\n$blue-600: #2D3073\n$blue-700: #182160\n$blue-800: #002A67\n$blue-900: #00174F\n\n$border-bottom-title: #BBBBBB\n\n$dark-50: #717171\n$dark-100: #5A5A5A\n$dark-200: #444444\n$dark-300: #2D2D2D\n\n$green-400: #00D04C\n$green-600: #00AC29\n$green-700: #009A14\n\n$light-300: #B4B4B4\n$light-400: #9E9E9E\n$light-700: #686868\n\n$red-500: #FF3333\n$red-600: #d32f2f\n$red-700: #A72626\n\n$statusLabelActive: #00BE3B\n$statusLabelPause: #535098\n$statusLabelClose: #EF4D43\n$statusLabelUnused: #9E9E9E\n\n$tag-text: #003F80\n\n$white: #fff\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": `styles_form__U5zxt`,
	"selects": `styles_selects__82+M+`,
	"input": `styles_input__EKCcJ`,
	"dateInputs": `styles_dateInputs__hXyuc`,
	"dateInputsMargin": `styles_dateInputsMargin__ooVeL`,
	"errorDate": `styles_errorDate__PgO1m`
};
export default ___CSS_LOADER_EXPORT___;
