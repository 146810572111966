// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_title__cXgsQ {
  display: flex;
  border-bottom: 0.5px solid #BBBBBB;
  color: #2D3073;
  font-size: 28px;
  font-weight: bold;
  padding: 17px 0;
  padding-left: 24px;
  margin: 0 110px 24px 0;
}
.style_title__cXgsQ .style_subItem__s4-Fa {
  display: flex;
  align-items: center;
  margin-left: 8px;
}
.style_title__cXgsQ .style_subItem__s4-Fa .style_icon__X6e9H {
  fill: #002A67;
  transform: rotate(180deg);
}
.style_title__cXgsQ .style_subItem__s4-Fa .style_subItemText__Ig9cn {
  font-size: 20px;
  font-weight: 600;
  margin: 0 8px;
}`, "",{"version":3,"sources":["webpack://./src/components/pageTitle/style.module.sass","webpack://./src/styles/_colors.sass"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,kCAAA;EACA,cCGS;EDFT,eAAA;EACA,iBAAA;EACA,eAAA;EACA,kBAAA;EACA,sBAAA;AADF;AAGE;EACE,aAAA;EACA,mBAAA;EACA,gBAAA;AADJ;AAGI;EACE,aCRK;EDSL,yBAAA;AADN;AAGI;EACE,eAAA;EACA,gBAAA;EACA,aAAA;AADN","sourcesContent":["@import 'styles/_colors.sass'\n\n.title\n  display: flex\n  border-bottom: 0.5px solid $border-bottom-title\n  color: $blue-600\n  font-size: 28px\n  font-weight: bold\n  padding: 17px 0\n  padding-left: 24px\n  margin: 0 110px 24px 0\n\n  .subItem\n    display: flex\n    align-items: center\n    margin-left: 8px\n\n    .icon\n      fill: $blue-800\n      transform: rotate(180deg)\n\n    .subItemText\n      font-size: 20px\n      font-weight: 600\n      margin: 0 8px\n","$background-menu: #F8F8FB\n\n$ligth-blue-600: #00559A\n\n$edit-icon: #6661AB\n\n$blue-200: #7973BF\n$blue-500: #404085\n$blue-600: #2D3073\n$blue-700: #182160\n$blue-800: #002A67\n$blue-900: #00174F\n\n$border-bottom-title: #BBBBBB\n\n$dark-50: #717171\n$dark-100: #5A5A5A\n$dark-200: #444444\n$dark-300: #2D2D2D\n\n$green-400: #00D04C\n$green-600: #00AC29\n$green-700: #009A14\n\n$light-300: #B4B4B4\n$light-400: #9E9E9E\n$light-700: #686868\n\n$red-500: #FF3333\n$red-600: #d32f2f\n$red-700: #A72626\n\n$statusLabelActive: #00BE3B\n$statusLabelPause: #535098\n$statusLabelClose: #EF4D43\n$statusLabelUnused: #9E9E9E\n\n$tag-text: #003F80\n\n$white: #fff\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `style_title__cXgsQ`,
	"subItem": `style_subItem__s4-Fa`,
	"icon": `style_icon__X6e9H`,
	"subItemText": `style_subItemText__Ig9cn`
};
export default ___CSS_LOADER_EXPORT___;
