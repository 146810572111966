// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_footerFloat__PBcKw {
  position: fixed;
  display: flex;
  justify-content: flex-end;
  bottom: 0;
  background-color: rgba(210, 211, 211, 0.2);
  -webkit-backdrop-filter: blur(2.5px);
          backdrop-filter: blur(2.5px);
  width: 100%;
  z-index: 100;
}
.styles_footerFloat__PBcKw .styles_floatButton__TDpfK {
  margin: 16px 0;
  margin-right: 96px;
  font-size: 18px;
  padding: 24px 0;
  width: 302px;
  background-color: #00D04C;
  transition: 0.2s all ease;
}
.styles_footerFloat__PBcKw .styles_floatButton__TDpfK:hover {
  background-color: #00AC29;
}`, "",{"version":3,"sources":["webpack://./src/components/floatButtonForm/styles.module.sass","webpack://./src/styles/_colors.sass"],"names":[],"mappings":"AAEA;EACE,eAAA;EACA,aAAA;EACA,yBAAA;EACA,SAAA;EACA,0CAAA;EACA,oCAAA;UAAA,4BAAA;EACA,WAAA;EACA,YAAA;AADF;AAGE;EACE,cAAA;EACA,kBAAA;EACA,eAAA;EACA,eAAA;EACA,YAAA;EACA,yBCEQ;EDDR,yBAAA;AADJ;AAGE;EACE,yBCDQ;ADAZ","sourcesContent":["@import 'styles/_colors.sass'\n\n.footerFloat\n  position: fixed\n  display: flex\n  justify-content: flex-end\n  bottom: 0\n  background-color: #d2d3d333\n  backdrop-filter: blur(2.5px)\n  width: 100%\n  z-index: 100\n\n  .floatButton\n    margin: 16px 0\n    margin-right: 96px\n    font-size: 18px\n    padding: 24px 0\n    width: 302px\n    background-color: $green-400\n    transition: 0.2s all ease\n\n  .floatButton:hover\n    background-color: $green-600\n","$background-menu: #F8F8FB\n\n$ligth-blue-600: #00559A\n\n$edit-icon: #6661AB\n\n$blue-200: #7973BF\n$blue-500: #404085\n$blue-600: #2D3073\n$blue-700: #182160\n$blue-800: #002A67\n$blue-900: #00174F\n\n$border-bottom-title: #BBBBBB\n\n$dark-50: #717171\n$dark-100: #5A5A5A\n$dark-200: #444444\n$dark-300: #2D2D2D\n\n$green-400: #00D04C\n$green-600: #00AC29\n$green-700: #009A14\n\n$light-300: #B4B4B4\n$light-400: #9E9E9E\n$light-700: #686868\n\n$red-500: #FF3333\n$red-600: #d32f2f\n$red-700: #A72626\n\n$statusLabelActive: #00BE3B\n$statusLabelPause: #535098\n$statusLabelClose: #EF4D43\n$statusLabelUnused: #9E9E9E\n\n$tag-text: #003F80\n\n$white: #fff\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"footerFloat": `styles_footerFloat__PBcKw`,
	"floatButton": `styles_floatButton__TDpfK`
};
export default ___CSS_LOADER_EXPORT___;
