// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_selects__E8LuA {
  margin-bottom: 48px !important;
}`, "",{"version":3,"sources":["webpack://./src/components/formAccessReports/components/selects/styles.module.sass"],"names":[],"mappings":"AAAA;EACE,8BAAA;AACF","sourcesContent":[".selects\n  margin-bottom: 48px !important\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"selects": `styles_selects__E8LuA`
};
export default ___CSS_LOADER_EXPORT___;
