// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_container__TcuhO {
  display: flex;
  align-items: center;
  justify-content: center;
}
.styles_container__TcuhO .styles_modalBox__E5GqI {
  background: #fff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  font-size: 18px;
  padding: 40px 53px;
  outline: none;
  width: 520px;
}
.styles_container__TcuhO .styles_modalBox__E5GqI .styles_gif__Ne\\+3q {
  margin-bottom: 24px;
  height: 56px;
  width: 56px;
}
.styles_container__TcuhO .styles_modalBox__E5GqI .styles_gifLoading__KK6Dq {
  margin-bottom: 14px;
  height: 96px;
}
.styles_container__TcuhO .styles_modalBox__E5GqI .styles_buttons__st-KQ {
  display: flex;
  justify-content: space-between;
  margin: 0 12px;
  margin-top: 40px;
}
.styles_container__TcuhO .styles_modalBox__E5GqI .styles_buttons__st-KQ .styles_leftButton__B362j {
  margin-right: 24px;
}`, "",{"version":3,"sources":["webpack://./src/components/modals/dialogbox/styles.module.sass","webpack://./src/styles/_colors.sass"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;AADF;AAGE;EACE,gBC+BI;ED9BJ,kBAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,kBAAA;EACA,eAAA;EACA,kBAAA;EACA,aAAA;EACA,YAAA;AADJ;AAGI;EACE,mBAAA;EACA,YAAA;EACA,WAAA;AADN;AAGI;EACE,mBAAA;EACA,YAAA;AADN;AAGI;EACE,aAAA;EACA,8BAAA;EACA,cAAA;EACA,gBAAA;AADN;AAGM;EACE,kBAAA;AADR","sourcesContent":["@import 'styles/_colors.sass'\n\n.container\n  display: flex\n  align-items: center\n  justify-content: center\n\n  .modalBox\n    background: $white\n    border-radius: 8px\n    display: flex\n    flex-direction: column\n    align-items: center\n    text-align: center\n    font-size: 18px\n    padding: 40px 53px\n    outline: none\n    width: 520px\n\n    .gif\n      margin-bottom: 24px\n      height: 56px\n      width: 56px\n\n    .gifLoading\n      margin-bottom: 14px\n      height: 96px\n\n    .buttons\n      display: flex\n      justify-content: space-between\n      margin: 0 12px\n      margin-top: 40px\n\n      .leftButton\n        margin-right: 24px\n","$background-menu: #F8F8FB\n\n$ligth-blue-600: #00559A\n\n$edit-icon: #6661AB\n\n$blue-200: #7973BF\n$blue-500: #404085\n$blue-600: #2D3073\n$blue-700: #182160\n$blue-800: #002A67\n$blue-900: #00174F\n\n$border-bottom-title: #BBBBBB\n\n$dark-50: #717171\n$dark-100: #5A5A5A\n$dark-200: #444444\n$dark-300: #2D2D2D\n\n$green-400: #00D04C\n$green-600: #00AC29\n$green-700: #009A14\n\n$light-300: #B4B4B4\n$light-400: #9E9E9E\n$light-700: #686868\n\n$red-500: #FF3333\n$red-600: #d32f2f\n$red-700: #A72626\n\n$statusLabelActive: #00BE3B\n$statusLabelPause: #535098\n$statusLabelClose: #EF4D43\n$statusLabelUnused: #9E9E9E\n\n$tag-text: #003F80\n\n$white: #fff\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `styles_container__TcuhO`,
	"modalBox": `styles_modalBox__E5GqI`,
	"gif": `styles_gif__Ne+3q`,
	"gifLoading": `styles_gifLoading__KK6Dq`,
	"buttons": `styles_buttons__st-KQ`,
	"leftButton": `styles_leftButton__B362j`
};
export default ___CSS_LOADER_EXPORT___;
