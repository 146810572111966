// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_selectsValue__7ZopG div {
  display: flex;
  text-wrap: wrap !important;
}

.styles_labelBrand__74erw {
  border-radius: 8px;
  color: #fff;
  display: flex;
  align-items: center;
  padding: 0.25rem 0.5rem;
  margin-right: 8px;
  gap: 0.25rem;
}
.styles_labelBrand__74erw svg {
  width: 1.5rem;
  height: 1.5rem;
  stroke: #FF3333;
}

.styles_checkbox__8JBOy {
  color: #2D2D2D;
  margin-right: 16px;
}`, "",{"version":3,"sources":["webpack://./src/components/select/styles.module.sass","webpack://./src/styles/_colors.sass"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,0BAAA;AADF;;AAGA;EACE,kBAAA;EACA,WC+BM;ED9BN,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,iBAAA;EACA,YAAA;AAAF;AAEE;EACE,aAAA;EACA,cAAA;EACA,eCUM;ADVV;;AASA;EACE,cCVS;EDWT,kBAAA;AANF","sourcesContent":["@import 'styles/_colors.sass'\n\n.selectsValue div\n  display: flex\n  text-wrap: wrap!important\n\n.labelBrand\n  border-radius: 8px\n  color: $white\n  display: flex\n  align-items: center\n  padding: 0.25rem 0.5rem\n  margin-right: 8px\n  gap: 0.25rem\n\n  svg\n    width: 1.5rem\n    height: 1.5rem\n    stroke: $red-500\n\n// .labelBrand::before\n//   position: relative\n//   display: flex\n//   content: url(/statics/icons/deleteSeletedItem.svg)\n//   padding-right: 4px\n//   top: 12%\n\n.checkbox\n  color: $dark-300\n  margin-right: 16px\n","$background-menu: #F8F8FB\n\n$ligth-blue-600: #00559A\n\n$edit-icon: #6661AB\n\n$blue-200: #7973BF\n$blue-500: #404085\n$blue-600: #2D3073\n$blue-700: #182160\n$blue-800: #002A67\n$blue-900: #00174F\n\n$border-bottom-title: #BBBBBB\n\n$dark-50: #717171\n$dark-100: #5A5A5A\n$dark-200: #444444\n$dark-300: #2D2D2D\n\n$green-400: #00D04C\n$green-600: #00AC29\n$green-700: #009A14\n\n$light-300: #B4B4B4\n$light-400: #9E9E9E\n$light-700: #686868\n\n$red-500: #FF3333\n$red-600: #d32f2f\n$red-700: #A72626\n\n$statusLabelActive: #00BE3B\n$statusLabelPause: #535098\n$statusLabelClose: #EF4D43\n$statusLabelUnused: #9E9E9E\n\n$tag-text: #003F80\n\n$white: #fff\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"selectsValue": `styles_selectsValue__7ZopG`,
	"labelBrand": `styles_labelBrand__74erw`,
	"checkbox": `styles_checkbox__8JBOy`
};
export default ___CSS_LOADER_EXPORT___;
