// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_container__Nufkm {
  margin-bottom: 100px;
}

.styles_addCategory__lNe0G {
  display: flex;
  height: 3.5rem;
  width: 3.5rem;
  position: fixed;
  right: 2%;
  bottom: 5%;
  padding: 0.75rem;
  z-index: 10;
  align-content: center;
  align-items: center;
  border-radius: 100%;
  background-color: #00D04C;
  box-shadow: 0px 4px 30px 5px rgba(0, 0, 0, 0.15);
  stroke: #fff;
  fill: #fff;
  transition: 0.3s background ease;
  cursor: pointer;
}

.styles_addCategory__lNe0G:hover {
  background-color: #009A14;
}

.styles_paragraph__oCLlT {
  color: #686868;
}`, "",{"version":3,"sources":["webpack://./src/screens/distribution-center/styles.module.sass","webpack://./src/styles/_colors.sass"],"names":[],"mappings":"AAEA;EACE,oBAAA;AADF;;AAGA;EACE,aAAA;EACA,cAAA;EACA,aAAA;EAEA,eAAA;EACA,SAAA;EACA,UAAA;EACA,gBAAA;EACA,WAAA;EAEA,qBAAA;EACA,mBAAA;EAEA,mBAAA;EACA,yBAAA;EACA,gDAAA;EACA,YCiBM;EDhBN,UCgBM;EDdN,gCAAA;EAEA,eAAA;AALF;;AAOA;EACE,yBCRU;ADIZ;;AAMA;EACE,cCPU;ADIZ","sourcesContent":["@import 'styles/_colors.sass'\n\n.container\n  margin-bottom: 100px\n\n.addCategory\n  display: flex\n  height: 3.5rem\n  width: 3.5rem\n\n  position: fixed\n  right: 2%\n  bottom: 5%\n  padding: 0.75rem\n  z-index: 10\n\n  align-content: center\n  align-items: center\n\n  border-radius: 100%\n  background-color: $green-400\n  box-shadow: 0px 4px 30px 5px rgba(0, 0, 0, 0.15)\n  stroke: $white\n  fill: $white\n\n  transition: 0.3s background ease\n\n  cursor: pointer\n\n.addCategory:hover\n  background-color: $green-700\n\n.paragraph\n  color: $light-700\n","$background-menu: #F8F8FB\n\n$ligth-blue-600: #00559A\n\n$edit-icon: #6661AB\n\n$blue-200: #7973BF\n$blue-500: #404085\n$blue-600: #2D3073\n$blue-700: #182160\n$blue-800: #002A67\n$blue-900: #00174F\n\n$border-bottom-title: #BBBBBB\n\n$dark-50: #717171\n$dark-100: #5A5A5A\n$dark-200: #444444\n$dark-300: #2D2D2D\n\n$green-400: #00D04C\n$green-600: #00AC29\n$green-700: #009A14\n\n$light-300: #B4B4B4\n$light-400: #9E9E9E\n$light-700: #686868\n\n$red-500: #FF3333\n$red-600: #d32f2f\n$red-700: #A72626\n\n$statusLabelActive: #00BE3B\n$statusLabelPause: #535098\n$statusLabelClose: #EF4D43\n$statusLabelUnused: #9E9E9E\n\n$tag-text: #003F80\n\n$white: #fff\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `styles_container__Nufkm`,
	"addCategory": `styles_addCategory__lNe0G`,
	"paragraph": `styles_paragraph__oCLlT`
};
export default ___CSS_LOADER_EXPORT___;
