// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_form__huppk {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
}

.styles_consultButton__0hTqZ {
  display: flex;
  width: 100%;
  height: 72px;
  padding: 8px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  flex-shrink: 0;
  margin-top: 48px;
  background-color: #00D04C;
}`, "",{"version":3,"sources":["webpack://./src/components/formAccessReports/styles.module.sass","webpack://./src/styles/_colors.sass"],"names":[],"mappings":"AAEA;EACE,aAAA;EACA,sBAAA;EACA,gBAAA;AADF;;AAGA;EACE,aAAA;EACA,WAAA;EACA,YAAA;EACA,YAAA;EACA,uBAAA;EACA,mBAAA;EACA,QAAA;EACA,cAAA;EACA,gBAAA;EACA,yBCGU;ADHZ","sourcesContent":["@import 'styles/_colors.sass'\n\n.form\n  display: flex\n  flex-direction: column\n  margin-top: 24px\n\n.consultButton\n  display: flex\n  width: 100%\n  height: 72px\n  padding: 8px\n  justify-content: center\n  align-items: center\n  gap: 8px\n  flex-shrink: 0\n  margin-top: 48px\n  background-color: $green-400\n","$background-menu: #F8F8FB\n\n$ligth-blue-600: #00559A\n\n$edit-icon: #6661AB\n\n$blue-200: #7973BF\n$blue-500: #404085\n$blue-600: #2D3073\n$blue-700: #182160\n$blue-800: #002A67\n$blue-900: #00174F\n\n$border-bottom-title: #BBBBBB\n\n$dark-50: #717171\n$dark-100: #5A5A5A\n$dark-200: #444444\n$dark-300: #2D2D2D\n\n$green-400: #00D04C\n$green-600: #00AC29\n$green-700: #009A14\n\n$light-300: #B4B4B4\n$light-400: #9E9E9E\n$light-700: #686868\n\n$red-500: #FF3333\n$red-600: #d32f2f\n$red-700: #A72626\n\n$statusLabelActive: #00BE3B\n$statusLabelPause: #535098\n$statusLabelClose: #EF4D43\n$statusLabelUnused: #9E9E9E\n\n$tag-text: #003F80\n\n$white: #fff\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": `styles_form__huppk`,
	"consultButton": `styles_consultButton__0hTqZ`
};
export default ___CSS_LOADER_EXPORT___;
