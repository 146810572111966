// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_container__9BDA5 {
  background: linear-gradient(90deg, #172C77 0%, #7F76B5 102.5%);
  display: flex;
  justify-content: space-between;
  padding: 24px 24px;
  position: fixed;
  width: 97vw;
  z-index: 2;
}
.styles_container__9BDA5 .styles_exit__l73mA {
  fill: #fff;
  cursor: pointer;
}
.styles_container__9BDA5 .styles_menuContainer__WyImd {
  display: flex;
  align-items: center;
  justify-content: center;
}
.styles_container__9BDA5 .styles_menuContainer__WyImd .styles_logo__IZKqb {
  fill: #fff;
}
.styles_container__9BDA5 .styles_menuContainer__WyImd .styles_meunIcon__B47gT {
  margin-right: 24px;
  stroke: #fff;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/components/header/styles.module.sass","webpack://./src/styles/_colors.sass"],"names":[],"mappings":"AAEA;EACE,8DAAA;EACA,aAAA;EACA,8BAAA;EACA,kBAAA;EACA,eAAA;EACA,WAAA;EACA,UAAA;AADF;AAGE;EACE,UC2BI;ED1BJ,eAAA;AADJ;AAGE;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;AADJ;AAGI;EACE,UCkBE;ADnBR;AAGI;EACE,kBAAA;EACA,YCcE;EDbF,eAAA;AADN","sourcesContent":["@import 'styles/_colors.sass'\n\n.container\n  background: linear-gradient(90deg, #172C77 0%, #7F76B5 102.5%)\n  display: flex\n  justify-content: space-between\n  padding: 24px 24px\n  position: fixed\n  width: 97vw\n  z-index: 2\n\n  .exit\n    fill: $white\n    cursor: pointer\n\n  .menuContainer\n    display: flex\n    align-items: center\n    justify-content: center\n\n    .logo\n      fill: $white\n\n    .meunIcon\n      margin-right: 24px\n      stroke: $white\n      cursor: pointer\n","$background-menu: #F8F8FB\n\n$ligth-blue-600: #00559A\n\n$edit-icon: #6661AB\n\n$blue-200: #7973BF\n$blue-500: #404085\n$blue-600: #2D3073\n$blue-700: #182160\n$blue-800: #002A67\n$blue-900: #00174F\n\n$border-bottom-title: #BBBBBB\n\n$dark-50: #717171\n$dark-100: #5A5A5A\n$dark-200: #444444\n$dark-300: #2D2D2D\n\n$green-400: #00D04C\n$green-600: #00AC29\n$green-700: #009A14\n\n$light-300: #B4B4B4\n$light-400: #9E9E9E\n$light-700: #686868\n\n$red-500: #FF3333\n$red-600: #d32f2f\n$red-700: #A72626\n\n$statusLabelActive: #00BE3B\n$statusLabelPause: #535098\n$statusLabelClose: #EF4D43\n$statusLabelUnused: #9E9E9E\n\n$tag-text: #003F80\n\n$white: #fff\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `styles_container__9BDA5`,
	"exit": `styles_exit__l73mA`,
	"menuContainer": `styles_menuContainer__WyImd`,
	"logo": `styles_logo__IZKqb`,
	"meunIcon": `styles_meunIcon__B47gT`
};
export default ___CSS_LOADER_EXPORT___;
