// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_title__8qG49 {
  color: #003F80;
  font-size: 2em;
  font-weight: 600;
  margin: 0;
}

.styles_subtitle__PSQFX {
  color: #878787;
  font-weight: 600;
  font-size: 1em;
}`, "",{"version":3,"sources":["webpack://./src/screens/errorPage/styles.module.sass"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,cAAA;EACA,gBAAA;EACA,SAAA;AACF;;AACA;EACE,cAAA;EACA,gBAAA;EACA,cAAA;AAEF","sourcesContent":[".title\n  color:#003F80\n  font-size: 2em\n  font-weight: 600\n  margin: 0\n\n.subtitle\n  color:#878787\n  font-weight: 600\n  font-size: 1em\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `styles_title__8qG49`,
	"subtitle": `styles_subtitle__PSQFX`
};
export default ___CSS_LOADER_EXPORT___;
