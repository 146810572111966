// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_label__1TMdY {
  font-size: 20px;
  font-weight: 700;
  color: #2D3073;
  margin-bottom: 24px;
  -webkit-user-select: none;
          user-select: none;
  margin-top: 40px;
}

.styles_selection__u5CCS {
  margin-right: 48px;
  margin-left: 136px;
}

.styles_selects__wDmRt {
  margin-bottom: 32px;
}`, "",{"version":3,"sources":["webpack://./src/components/modals/Filter/components/filterSelects/styles.module.sass","webpack://./src/styles/_colors.sass"],"names":[],"mappings":"AAEA;EACE,eAAA;EACA,gBAAA;EACA,cCGS;EDFT,mBAAA;EACA,yBAAA;UAAA,iBAAA;EACA,gBAAA;AADF;;AAGA;EACE,kBAAA;EACA,kBAAA;AAAF;;AAEA;EACE,mBAAA;AACF","sourcesContent":["@import 'styles/_colors.sass'\n\n.label\n  font-size: 20px\n  font-weight: 700\n  color: $blue-600\n  margin-bottom: 24px\n  user-select: none\n  margin-top: 40px\n\n.selection\n  margin-right: 48px\n  margin-left: 136px\n\n.selects\n  margin-bottom: 32px\n","$background-menu: #F8F8FB\n\n$ligth-blue-600: #00559A\n\n$edit-icon: #6661AB\n\n$blue-200: #7973BF\n$blue-500: #404085\n$blue-600: #2D3073\n$blue-700: #182160\n$blue-800: #002A67\n$blue-900: #00174F\n\n$border-bottom-title: #BBBBBB\n\n$dark-50: #717171\n$dark-100: #5A5A5A\n$dark-200: #444444\n$dark-300: #2D2D2D\n\n$green-400: #00D04C\n$green-600: #00AC29\n$green-700: #009A14\n\n$light-300: #B4B4B4\n$light-400: #9E9E9E\n$light-700: #686868\n\n$red-500: #FF3333\n$red-600: #d32f2f\n$red-700: #A72626\n\n$statusLabelActive: #00BE3B\n$statusLabelPause: #535098\n$statusLabelClose: #EF4D43\n$statusLabelUnused: #9E9E9E\n\n$tag-text: #003F80\n\n$white: #fff\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"label": `styles_label__1TMdY`,
	"selection": `styles_selection__u5CCS`,
	"selects": `styles_selects__wDmRt`
};
export default ___CSS_LOADER_EXPORT___;
