// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_title__KgfIh {
  color: #002A67;
  font-size: 18px;
  font-weight: 700;
  margin: 24px;
}

.styles_selectionTitle__xlZ6y {
  color: #2D3073;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin-bottom: 32px;
}

.styles_addUserForm__Py43o {
  flex: 1 1;
  margin-right: 88px;
}

.styles_form__S8N5l {
  flex-direction: column;
  margin-top: 24px;
}

.styles_selects__IIKzb {
  margin-bottom: 80px !important;
  width: 480px !important;
}`, "",{"version":3,"sources":["webpack://./src/components/formUserCreation/components/select/styles.module.sass","webpack://./src/styles/_colors.sass"],"names":[],"mappings":"AAEA;EACI,cCOO;EDNP,eAAA;EACA,gBAAA;EACA,YAAA;AADJ;;AAGA;EACI,cCDO;EDEP,eAAA;EACA,kBAAA;EACA,gBAAA;EACA,mBAAA;EACA,mBAAA;AAAJ;;AAEA;EACI,SAAA;EACA,kBAAA;AACJ;;AACA;EACI,sBAAA;EACA,gBAAA;AAEJ;;AAAA;EACI,8BAAA;EACA,uBAAA;AAGJ","sourcesContent":["@import 'styles/_colors.sass'\n\n.title\n    color: $blue-800\n    font-size: 18px\n    font-weight: 700\n    margin: 24px\n\n.selectionTitle\n    color: $blue-600\n    font-size: 20px\n    font-style: normal\n    font-weight: 400\n    line-height: normal\n    margin-bottom: 32px\n\n.addUserForm\n    flex: 1\n    margin-right: 88px\n\n.form\n    flex-direction: column\n    margin-top: 24px\n\n.selects\n    margin-bottom: 80px !important\n    width: 480px !important","$background-menu: #F8F8FB\n\n$ligth-blue-600: #00559A\n\n$edit-icon: #6661AB\n\n$blue-200: #7973BF\n$blue-500: #404085\n$blue-600: #2D3073\n$blue-700: #182160\n$blue-800: #002A67\n$blue-900: #00174F\n\n$border-bottom-title: #BBBBBB\n\n$dark-50: #717171\n$dark-100: #5A5A5A\n$dark-200: #444444\n$dark-300: #2D2D2D\n\n$green-400: #00D04C\n$green-600: #00AC29\n$green-700: #009A14\n\n$light-300: #B4B4B4\n$light-400: #9E9E9E\n$light-700: #686868\n\n$red-500: #FF3333\n$red-600: #d32f2f\n$red-700: #A72626\n\n$statusLabelActive: #00BE3B\n$statusLabelPause: #535098\n$statusLabelClose: #EF4D43\n$statusLabelUnused: #9E9E9E\n\n$tag-text: #003F80\n\n$white: #fff\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `styles_title__KgfIh`,
	"selectionTitle": `styles_selectionTitle__xlZ6y`,
	"addUserForm": `styles_addUserForm__Py43o`,
	"form": `styles_form__S8N5l`,
	"selects": `styles_selects__IIKzb`
};
export default ___CSS_LOADER_EXPORT___;
